<template>
    <div class="card p-3">

        <div class="row mb-3" v-show="!show_module">
            <div class="col-md-4 text-center">
                <b-skeleton-img
                    v-if="!loaded"
                    style="aspect-ratio: 320 / 480;"
                />
                <img v-show="loaded" :src="module.imgUrl" alt="generica_modulo" class="img-fluid img-module"
                     @load="loaded=true">
                <span v-if="module.show_title" :style="
                            `
                             position: absolute;
                             bottom: 20px;
                             z-index: 1000;
                             font-size: 16px;
                             font-weight: bold;
                             line-height: 1;
                             top: 380px;
                             left: 15px;
                             right: 15px;
                             color: ${module.mentoring_color ? color : '#FFF'}
                            `">
                        {{ module.title }}
                    </span>
                <div class="d-flex justify-content-center top-middle mt-3">
                    <span class="bg-soft-info rounded-circle text-center balloon pointer"
                          @click="show_module=true" title="Editar Módulo">
                        <i class="fa fa-pencil-alt text-info"></i>
                    </span>
                </div>
            </div>

            <div class="col-md-8">
                <div class="col-12 text-end" v-show="!show_drag && module.contents.length > 1">
                    <i class="fa fa-sort pointer" @click="show_drag=true"></i>
                </div>
                <div class="row" v-if="!show_drag">

                    <div class="col-12 text-center" v-if="!show_content">
                        <h3>Conteúdos do Módulo</h3>
                        <i class="fa fa-plus-circle text-muted fa-2x pointer" @click="show_content=true"></i>
                    </div>

                    <div class="col-12 mt-3">
                        <div class="row position relative"
                             v-for="(content, i) in module.contents"
                             :key="'collapse-item-' + content.id" @mouseenter="mouseOn = content.id"
                             @mouseleave="mouseOn = null">
                            <div class="col-12 mb-3 d-flex" :class="{'opacity-25': mouseOn === content.id}">
                                <i class="fa fa-video mr-2 my-auto" v-if="content.type == 1"></i>
                                <i class="fa fa-download mr-2 my-auto" v-else-if="content.type == 2"></i>
                                <i class="fa fa-question-circle mr-2 my-auto" v-else-if="content.type == 3"></i>
                                <i class="fa fa-question-circle mr-2 my-auto" v-else-if="content.type == 4"></i>
                                <i class="fa fa-calendar mr-2 my-auto" v-else-if="content.type == 5"></i>
                                <i class="fa fa-file mr-2 my-auto" v-else-if="content.type == 6"></i>
                                <span class="font-size-16pt">
                                    {{ content.subject }}
                                </span>
                            </div>

                            <BCol class="position-absolute d-flex justify-content-center" v-if="mouseOn === content.id"
                                  :style="`top: ${i * 40 - 10}px`">
                                <span class="bg-soft-info rounded-circle mx-2 my-auto text-center balloon pointer"
                                      @click="setContent(i); mouseOn = null">
                                    <i class="fa fa-pencil-alt text-info"></i>
                                </span>
                                <span class="bg-soft-danger rounded-circle mx-2 my-auto text-center balloon pointer"
                                      @click="removeContent(i)">
                                    <i class="fa fa-trash text-danger"></i>
                                </span>
                            </BCol>

                        </div>
                    </div>



                </div>

                <div class="row p-2" v-if="show_content">

                    <div class="col-md-12 mb-3">
                        <img v-if="content.bannerUrl" :src="content.bannerUrl" id="mentoring_img"
                            style="width: 142px;height: 80px;margin-left: auto;margin-right: auto;"
                            class="mentor-imagem" alt="nova_mobile_mentoria">
                        <Cropper v-else-if="content.banner && !content.bannerUrl" :img="content.banner"
                                 :proportion="1.78"
                                 @set-img="setImgContent" @reset-logo="resetContent"/>
                        <img v-else class="img-fluid"
                            style="width: 142px;height: 80px;margin-left: auto;margin-right: auto;"
                            src="../../../assets/images/banner_content.png" id="mentoring_img" alt="mobile_mentoria">
                    </div>
                    <div class="col-md-12 my-auto mb-3">
                        <label class="form-label mt-2">Banner do Conteúdo</label>
                        <input type="file" id="mobile" class="form-control mb-3" @change="handleImageChangeContent"
                               required accept="image/*">
                        <div class="alert alert-warning">
                            Dimensões indicadas: 142 x 80
                        </div>
                        <div class="alert alert-danger" v-if="show_alert_content">
                            As dimensões da sua imagem são menores que o recomendado, o que pode resultar em
                            distorções ou perda de qualidade ao ser exibida.
                        </div>
                    </div>


                    <div class="col-md-6 mb-3">
                        <label class="form-label">Título</label>
                        <input type="text" class="form-control" required
                               placeholder="Assunto da Atividade/Conteúdo"
                               v-model="content.subject">
                    </div>

                    <div class="col-md-6 mb-3">
                        <label class="form-label">Tipo</label>
                        <select class="form-control" required v-model="content.type">
                            <option value="">Selecionar</option>
                            <option value="1">Vídeo</option>
                            <option value="2">Arquivo</option>
                            <option value="3">Formulário</option>
                            <option value="4">Atividade</option>
                            <option value="5">Agenda</option>
                            <option value="6">Leitura</option>
                        </select>
                    </div>

                    <div class="col-md-12 mb-3" v-if="content.type == 1">
                        <label class="form-label">Link</label>
                        <input class="form-control mr-2" type="text" placeholder="Link do Vídeo"
                               v-model="content.video" required>
                    </div>

                    <div class="mb-3 col-md-12 d-flex w-100" v-if="content.type == 2">
                        <div class="flex-grow-1">
                            <label class="form-label" v-if="!content.url">Arquivo</label>
                            <label class="form-label" v-else>Trocar Arquivo</label>
                            <input class="form-control mr-2"
                                   type="file" required id="fileContent"
                                   @change="handleFileUpload"
                                   v-if="content.type == 2">
                        </div>
                        <a v-if="content.url" :href="content.url" data-toggle="tooltip" title="Visualizar arquivo"
                           class="copy-file mt-3" target="_blank">
                            <i class="fas fa-download ml-3 pointer mt-4"></i>
                        </a>
                    </div>


                    <div class="col-md-12 mb-3" v-else-if="content.type == 3">
                        <label class="form-label">Formulário</label>
                        <select class="form-control mr-2" required v-model="content.form_id"
                                v-if="content.type == 3">
                            <option value="">Selecione o Formulário</option>
                            <option v-for="form in forms" :key="'form-option-' + form.id"
                                    :value="form.id">
                                {{ form.title }}
                            </option>
                        </select>
                    </div>
                    <div class="col-md-1 mb-4 mt-auto"
                         v-if="content.type == 3 && content.form_id && content.form_id !== ''">
                        <!-- <a :href="`${env.menteeUrl}formulario/preview/${encondeId(content.form_id)}`"
                           target="_blank">
                            <i class="fa fa-search text-muted mx-1" title="Preview"></i>
                        </a> -->
                    </div>
                    <div class="col-md-12 mb-3" v-else-if="content.type == 6">
                        <label class="form-label">Descrição</label>
                        <vue-editor class="editorSize" v-model="content.read"
                                    required :editor-toolbar="customToolbar"/>
                    </div>

                    <div style="display: contents" v-else-if="content.type == 4">
                        <div class="col-md-12 mb-3">
                            <label class="form-label">Descrição</label>
                            <vue-editor class="editorSize" v-model="content.activity.description"
                                        required :editor-toolbar="customToolbar"/>
                        </div>

                        <div class="col-md-4 mb-3">
                            <label class="form-label">Tipo <span class="text-danger">*</span></label>
                            <select name="category" v-model="content.activity.type" class="form-control">
                                <option value="">Selecione</option>
                                <option value="1">Dissertativa</option>
                                <option value="2">Única escolha</option>
                                <option value="3">Multipla escolha</option>
                                <option value="4">Upload de arquivo</option>
                            </select>
                        </div>
                        <div class="col-md-12 mb-3"
                             v-if="content.activity.type == '2' || content.activity.type == '3'">
                            <label class="form-label">Alternativas </label>
                            <input
                                class="form-control"
                                v-model="currentAlternative"
                                @keyup.enter="addAlternative()"
                                @keydown.tab.prevent="addAlternative()"
                                placeholder="Digite a alternativa e precione Enter OU Tab..."
                            />
                        </div>

                        <BCol sm="12">
                            <BBadge variant="light" class="w-100 text-start p-2 mb-1 d-flex justify-content-between"
                                    v-for="(a, i) in content.activity.alternatives"
                                    :key="`alternative-` + a.id">
                                <span class="fs-12" style="white-space: break-spaces">{{ a.alternative }}</span>
                                <div class="my-auto">
                                    <i class="fa fs-12 fa-times-circle my-auto text-muted pointer"
                                       @click="removeAlternative(i)"></i>
                                </div>
                            </BBadge>
                        </BCol>
                    </div>

                    <div class="col-12 text-end mt-3">
                        <button type="button" class="btn btn-outline-danger" @click="show_content=false">Cancelar
                        </button>
                        <button type="button" class="btn btn-outline-success ml-2" @click="saveContent">
                            Confirmar
                        </button>
                    </div>


                </div>


                <!--                                <PreviewDrag-->
                <!--                                    v-else-->
                <!--                                    @cancel="show_drag=false"-->
                <!--                                    @update-contents="updateContents"-->
                <!--                                />-->

            </div>

            <div class="col-12" v-if="!show_module && !show_content && !show_drag">
                <div class="row">
                    <div class="col-12 text-end mt-3">
                        <button type="button" class="btn btn-outline-danger" @click="cancel">Cancelar</button>
                        <button type="button" class="btn btn-outline-success ml-2" @click="save">
                            Adicionar
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div class="row p-2" v-show="show_module">
            <div class="col">
                <div class="row">

                    <div class="col-12 mb-3">
                        <label class="form-label">Título do Módulo</label>
                        <input type="text" v-model="module.title" class="form-control"
                               placeholder="Título do Módulo" required>
                    </div>

                    <div class="col-12 mb-3">
                        <div class="d-flex justify-content-between">
                            <label class="form-label justify-content-between d-flex">
                                <span>
                                    Descrição
                                </span>
                            </label>
                            <div class="d-flex">
                                <button class="btn btn-outline-info btn-sm mb-1" id="buttonGenerateModule"
                                        type="button" :class="{disabled: disabled}"
                                        @click="generateDescriptionModule">
                                    Gerar automaticamente
                                </button>
                                <i v-b-tooltip.hover.right="'Gerar a descrição da mentoria via inteligência artificial de acordo com o título e o resumo.'"
                                   class="fa fa-info-circle ml-2 my-auto"></i>
                            </div>
                        </div>
                        <textarea class="form-control" placeholder="Insira uma descrição do módulo..."
                                  rows="5" v-model="module.description"></textarea>
                    </div>


                    <div class="col-md-6 mb-3">
                        <label class="form-label d-flex">Prazo Mentorado
                            <i v-b-tooltip.hover.right="'Prazo em dias para término da módulo'"
                               class="fa fa-info-circle ml-2 my-auto"></i>
                        </label>
                        <input type="number" v-model="module.deadline"
                               class="form-control" placeholder="Prazo em dias" required>
                    </div>

                    <div class="col-md-6 mb-3">
                        <label class="form-label d-flex">Prazo Mentor
                            <i v-b-tooltip.hover.right="'Prazo em dias para retorno do mentor a contar a partir da finalização do mentorado'"
                               class="fa fa-info-circle ml-2 my-auto"></i>
                        </label>
                        <input type="number" v-model="module.return_period"
                               class="form-control" placeholder="Prazo em dias" required>
                    </div>

                    <div class="col-12 mb-3">
                        <label class="form-label">Imagem do Módulo</label>
                        <input type="file" class="form-control" @change="handleImageChange" accept="image/*"
                               placeholder="Imagem do Módulo" required :id="`img${module.id}`">
                    </div>

                    <div class="col-12">
                        <div class="alert alert-warning">
                            Dimensões indicadas: 320 x 480px
                        </div>
                    </div>

                    <div class="col-12" v-if="show_alert">
                        <div class="alert alert-danger">
                            As dimensões da sua imagem são menores que o recomendado, o que pode resultar em
                            distorções ou perda de qualidade ao ser exibida.
                        </div>
                    </div>

                    <div class="col-12 mt-auto mb-3">
                        <div class="custom-control custom-checkbox">
                            <input :id="'checkShow' + module.id" type="checkbox"
                                   class="custom-control-input"
                                   v-model="module.show_title">
                            <label :for="'checkShow' + module.id"
                                   class="custom-control-label">
                                Exibir título do módulo
                            </label>
                            <i v-b-tooltip.hover.right="'Se este campo estiver selecionado o título do Módulo será exibido em cima da imagem'"
                               class="fa fa-info-circle ml-2"></i>
                        </div>
                    </div>

                    <div class="col-12 mt-auto mb-3" v-if="module.show_title">
                        <div class="custom-control custom-checkbox">
                            <input :id="'checkColor' + module.id" type="checkbox"
                                   class="custom-control-input"
                                   v-model="module.mentoring_color">
                            <label :for="'checkColor' + module.id"
                                   class="custom-control-label">
                                Título na cor da Mentoria
                            </label>
                            <i v-b-tooltip.hover.right="'Se este campo estiver selecionado o título do Módulo a ser exibido em cima da imagem ficará com a cor selecionada para a mentoria'"
                               class="fa fa-info-circle ml-2"></i>
                        </div>
                    </div>

                    <div class="col-12 mt-auto mb-3">
                        <div class="custom-control custom-checkbox">
                            <input :id="'checkAuth' + module.id" type="checkbox"
                                   class="custom-control-input"
                                   v-model="module.authorized">
                            <label :for="'checkAuth' + module.id"
                                   class="custom-control-label">
                                Liberado
                            </label>
                            <i v-b-tooltip.hover.right="'Se este campo estiver selecionado o aluno terá acesso ao módulos e seus conteúdos a partir da sua data de inscrição a mentoria.'"
                               class="fa fa-info-circle ml-2"></i>
                        </div>
                    </div>

                </div>
            </div>

            <div v-if="show" style="width: 320px!important;">
                <Cropper
                    :img="module.img"
                    :proportion="0.66"
                    @set-img="setImg"
                    @reset-logo="resetLogo"
                    ref="cropper"
                />
            </div>

            <div class="position-relative text-center" style="width: 320px" v-else>
                <img v-if="!module.imgUrl" src="@/assets/images/modulo.png" alt="generica_modulo"
                     class="img-fluid img-module">
                <img v-else :src="module.imgUrl" alt="generica_modulo" class="img-fluid img-module">
                <span v-if="module.show_title" :style="
                                    `
                                     position: absolute;
                                     top: 400px;
                                     z-index: 1000;
                                     left: 20px;
                                     right: 20px;
                                     font-size: 20px;
                                     font-weight: bold;
                                     max-width: 280px;
                                     color: ${module.mentoring_color ? color : '#FFF'}
                                    `">
                                {{ module.title }}
                            </span>
            </div>

            <div class="col-12 text-end mt-3" v-if="!show">
                <button type="button" class="btn btn-outline-danger" @click="cancel">Cancelar</button>
                <button type="button" class="btn btn-outline-success ml-2" @click="saveModule">
                    Confirmar
                </button>
            </div>
        </div>


    </div>
</template>
<!-- eslint-disable -->
<script>
import {VueEditor} from "vue2-editor";
import Cropper from "@/components/base/cropper.vue";
import PreviewEditModule from "@/views/mentoring/register/preview-edit-module.vue";
import PreviewEditContent from "@/views/mentoring/register/preview-edit-content.vue";
import PreviewDrag from "@/views/mentoring/register/preview-drag.vue";
import {ValidateForm} from "@/components/composables/cruds";
import {encondeId} from "@/components/composables/functions";
import env from "@/env";
import http from '@/http'
import {messageSwal} from "@/components/composables/messages";

export default {
    computed: {
        env() {
            return env
        }
    },
    components: {
        Cropper,
        PreviewEditModule,
        PreviewDrag,
        PreviewEditContent,
        VueEditor
    },

    data() {
        return {
            module: {
                id: new Date().getTime() + '_mod',
                title: '',
                description: '',
                deadline: '',
                return_period: '',
                show_title: false,
                img: '',
                imgUrl: '',
                mentoring_color: false,
                authorized: true,
                contents: []
            },
            content: {
                id: new Date().getTime() + '_cont',
                bannerUrl: '',
                banner: '',
                type: '',
                subject: '',
                video: '',
                doc: '',
                url: '',
                form_id: '',
                read: '',
                activity: {
                    description: '',
                    type: '',
                    alternatives: []
                }
            },
            indexContent: null,
            currentAlternative: '',
            visible: true,
            loaded: false,
            color: null,
            show: false,
            show_cropper_content: false,
            show_module: true,
            show_content: false,
            show_alert: false,
            show_drag: false,
            show_alert_content: false,
            disabled: false,
            mouseOn: null,
            customToolbar: [
                [{'size': ['small', false, 'large', 'huge']}],
                ["bold", "italic"],
                [{list: "ordered"}, {list: "bullet"}],
            ],
            forms: [],
        }
    },

    methods: {
        encondeId,

        generateDescriptionModule() {
            if (!this.disabled) {
                this.disabled = true;
                const content = this.module.title;
                this.$store.commit('api/setSpinner', true);
                http.post('mentorings/generate-description-module', {content})
                    .then(response => {
                        this.module.description = response.data[0];
                    })
                    .catch(e => {
                        console.error('Erro na requisição GPT:', e);
                    })
                    .finally(() => {
                        this.$store.commit('api/setSpinner', false);
                        this.disabled = false;
                    })
            }
        },

        updateContent(payload) {
            this.module.contents[this.indexContent] = payload;
        },

        cancel() {
            this.$emit('cancel');
        },

        removeContent(i) {
            this.module.contents.splice(i, 1);
        },

        setContent(i) {
            this.content = {...this.module.contents[i]};

            this.indexContent = i;
            this.show_content = true;
        },

        setImg(blob) {
            this.module.imgUrl = URL.createObjectURL(blob);
            this.module.img = new File([blob], "cropped-image.png", {type: 'image/png'});
            this.show = false;
        },

        resetLogo() {
            document.getElementById('img' + this.module.id).value = '';
            this.show_alert = false;
            this.show = false;
        },

        handleImageChange(event) {
            const input = event.target;
            if (input.files && input.files[0]) {
                const reader = new FileReader();

                reader.onload = (e) => {
                    this.module.img = e.target.result;
                    this.show = true;
                };
                const maxWidth = 320;
                const maxHeight = 480;
                if (input.width < maxWidth || input.height < maxHeight) this.show_alert = true
                reader.readAsDataURL(input.files[0]);
            } else {
                this.module.img = '';
                this.module.imgUrl = null;
                this.show = false;
                this.show_alert = false;
            }
        },

        setImgContent(blob) {
            this.content.bannerUrl = URL.createObjectURL(blob);
            this.content.banner = new File([blob], "cropped-image.png", {type: 'image/png'});
            this.show_cropper_content = false;
        },

        resetContent() {
            document.getElementById('img' + this.module.id).value = '';
            this.show_alert_content = false;
            this.show_cropper_content = false;
        },

        handleImageChangeContent(event) {
            const input = event.target;
            if (input.files && input.files[0]) {
                const reader = new FileReader();

                reader.onload = (e) => {
                    this.content.banner = e.target.result;
                    this.show_cropper_content = true;
                };
                const maxWidth = 320;
                const maxHeight = 480;
                if (input.width < maxWidth || input.height < maxHeight) this.show_alert_content = true
                reader.readAsDataURL(input.files[0]);
            } else {
                this.content.banner = '';
                this.content.bannerUrl = null;
                this.show_cropper_content = false;
                this.show_alert_content = false;
            }
        },

        handleFileUpload(event) {
            const file = event.target.files[0];

            if (file) {
                const fileUrl = URL.createObjectURL(file);

                this.content.doc = event.target.files[0];
                this.content.url = fileUrl;
            } else {
                this.content.doc = '';
                this.content.url = '';
            }
        },


        saveModule() {
            const required = {
                title: 'Título',
                description: 'Descrição',
                deadline: 'Prazo Mentorado',
                return_period: 'Prazo Mentor',
                img: 'Imagem do Módulo',
            }

            if (!ValidateForm(this.$root, this.module, required)) {
                return
            }

            this.show_module = false;
        },

        saveContent() {
            let required = {
                subject: 'Título',
                type: 'Tipo',
            }

            let required2 = null;

            if (this.content.type === '1') {
                required = {
                    ...required,
                    video: 'Link'
                }
            } else if (this.content.type === '2') {
                required = {
                    ...required,
                    doc: 'Arquivo'
                }
            } else if (this.content.type === '3') {
                required = {
                    ...required,
                    form_id: 'Formulário'
                }
            } else if (this.content.type === '4') {
                required2 = {
                    description: 'Descrição',
                    type: 'Tipo',
                }

                if (this.content.activity.type === '3' || this.content.activity.type === '3') {
                    required2 = {
                        ...required2,
                        alternatives: 'Alternativas'
                    }
                }

            } else if (this.content.type === '6') {
                required = {
                    ...required,
                    read: 'Descrição'
                }
            }

            if (!ValidateForm(this.$root, this.content, required)) {
                return
            }

            if (this.content.type === '4' && !ValidateForm(this.$root, this.content.activity, required2)) {
                return
            }

            if (this.indexContent !== null) {
                this.module.contents[this.indexContent] = this.content;
                this.indexContent = null;
            } else {
                this.module.contents.push(this.content);
            }

            this.content = {
                id: new Date().getTime() + '_cont',
                bannerUrl: '',
                banner: '',
                type: '',
                subject: '',
                video: '',
                doc: '',
                url: '',
                form_id: '',
                read: '',
                activity: {
                    description: '',
                    type: '',
                    alternatives: []
                }
            };

            this.show_content = false;
        },

        async save() {

            let result = true;
            if (this.currentAlternative.trim() !== '') {
                result = await messageSwal(
                    "Atenção!",
                    "Você não salvou todas as alternativas! Deseja mesmo continuar?",
                    "warning"
                )

            }

            if (result) {

                // Validando formulário
                let required = {
                    title: 'Título',
                    description: 'Descrição',
                    deadline: 'Prazo Mentorado',
                    return_period: 'Prazo Mentor',
                    contents: 'Conteúdos'
                }

                if (!ValidateForm(this.$root, this.module, required)) {
                    return;
                }

                this.$store.commit('mentoring/addModule', this.module);

                this.cancel();
            }

        },

        addAlternative() {
            this.content.activity.alternatives.push({
                id: new Date().getTime() + '_alter',
                alternative: this.currentAlternative
            });

            this.currentAlternative = '';
        },

        removeAlternative(index) {
            this.content.activity.alternatives.splice(index, 1);
        },

        getForms() {
            http.get('forms/?paginated=false')
                .then(response => {
                    this.forms = response.data.data
                    this.$emit('update-forms', response.data.data);
                })
        },


    },

    mounted() {
        this.getForms()
    },

    created() {
        this.color = document.getElementById('mentoring_color');
    }
}
</script>
