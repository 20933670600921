<template>
    <div>
        <div ref="mainContainer" class="row pr-2 mt-4">
            <div v-for="item in items" :key="item.id" class="w-100 mb-2">
                <div class="d-flex drag border border-dark p-2">
                    <i class="fa fa-video mr-2 my-auto" v-if="item.type == 1"></i>
                    <i class="fa fa-download mr-2 my-auto" v-else-if="item.type == 2"></i>
                    <i class="fa fa-question-circle mr-2 my-auto" v-else-if="item.type == 3"></i>
                    <i class="fa fa-question-circle mr-2 my-auto" v-else-if="item.type == 4"></i>
                    <i class="fa fa-calendar mr-2 my-auto" v-else-if="item.type == 5"></i>
                    <i class="fa fa-file mr-2 my-auto" v-else-if="item.type == 6"></i>
                    <span class="font-size-16pt">
                            {{ item.subject }}
                    </span>
                </div>
            </div>

            <div class="text-end mt-3 w-100" sm="12">
                <button class="btn btn-outline-danger" @click="cancel">Cancelar</button>
                <button class="btn btn-outline-success ml-1" @click="save">Confirmar</button>
            </div>

        </div>
    </div>
</template>

<script>
import Sortable from 'sortablejs';

export default {

    props: {
        indexModule: {
            required: true,
            type: Number
        }
    },

    data() {
        return {
            items: [],
            loaded: [],
            color: null
        };
    },
    mounted() {
        this.items = JSON.parse(JSON.stringify(this.$store.state.mentoring.modules[this.indexModule].contents));
        this.$nextTick(() => {
            // Configura o SortableJS para o container principal (não pode arrastar para subgrupos)
            Sortable.create(this.$refs.mainContainer, {
                group: {
                    name: 'main-group',
                    pull: 'clone', // permite apenas clonar, mas não mover para subgrupos
                    put: false,    // impede que os itens principais sejam colocados em outro grupo
                },
                animation: 150,
                onEnd: (event) => {
                    const movedItem = this.items.splice(event.oldIndex, 1)[0];
                    this.items.splice(event.newIndex, 0, movedItem);
                },
            });
        });
    },
    methods: {
        cancel() {
            this.$emit('cancel');
        },
        save() {
            this.$emit('update-contents', this.items);
        }
    },

    created() {
        this.color = document.getElementById('mentoring_color');
    }
};
</script>

<style scoped>

.drag {
    cursor: grab;
    border-radius: 5px;
}
</style>
