var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-center"},[(!_vm.loaded)?_c('b-skeleton-img',{staticStyle:{"aspect-ratio":"320 / 480"}}):_vm._e(),_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.loaded),expression:"loaded"}],staticClass:"img-fluid img-module",attrs:{"src":_vm.module.imgUrl,"alt":"generica_modulo"},on:{"load":function($event){_vm.loaded=true}}}),(_vm.module.show_title)?_c('span',{style:(`
                         position: absolute;
                         bottom: 20px;
                         z-index: 1000;
                         font-size: 16px;
                         font-weight: bold;
                         line-height: 1;
                         bottom: 90px;
                         left: 15px;
                         right: 15px;
                         color: ${_vm.module.mentoring_color ? _vm.color : '#FFF'}
                        `)},[_vm._v(" "+_vm._s(_vm.module.title)+" ")]):_vm._e(),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"d-flex justify-content-center top-middle"},[_c('span',{staticClass:"bg-soft-info rounded-circle mx-2 my-auto text-center balloon pointer",on:{"click":_vm.editModule}},[_c('i',{staticClass:"fa fa-eye text-info"})]),_c('span',{staticClass:"bg-soft-danger rounded-circle mx-2 my-auto text-center balloon pointer",on:{"click":_vm.removeModule}},[_c('i',{staticClass:"fa fa-trash text-danger"})])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }