<template>
    <div class="text-center ">
        <b-skeleton-img
            v-if="!loaded"
            style="aspect-ratio: 320 / 480;"
        />
        <img v-show="loaded" :src="module.imgUrl" alt="generica_modulo" class="img-fluid img-module" @load="loaded=true">
        <span v-if="module.show_title" :style="
                            `
                             position: absolute;
                             bottom: 20px;
                             z-index: 1000;
                             font-size: 16px;
                             font-weight: bold;
                             line-height: 1;
                             bottom: 90px;
                             left: 15px;
                             right: 15px;
                             color: ${module.mentoring_color ? color : '#FFF'}
                            `">
                        {{ module.title }}
                    </span>
        <div class="card-body">
            <div class="d-flex justify-content-center top-middle">
                <span class="bg-soft-info rounded-circle mx-2 my-auto text-center balloon pointer" @click="editModule">
                    <i class="fa fa-eye text-info"></i>
                </span>
                <span class="bg-soft-danger rounded-circle mx-2 my-auto text-center balloon pointer"
                      @click="removeModule">
                    <i class="fa fa-trash text-danger"></i>
                </span>
            </div>
        </div>
    </div>
</template>

<script>


export default {
    props: {
        module: {
            required: true,
            type: Object
        },
        indexModule: {
            required: true,
            type: Number
        }
    },

    data() {
        return {
            color: null,
            loaded: false
        }
    },

    methods: {
        removeModule() {
            this.$store.commit('mentoring/removeModule', this.indexModule);
        },

        editModule() {
            this.$emit('edit-module', this.indexModule);
        }
    },

    created() {
        this.color = document.getElementById('mentoring_color');
    }
}

</script>
