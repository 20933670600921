<template>
    <div>
        <div ref="mainContainer" class="row">
            <div v-for="item in items" :key="item.id" class="col-md-3 mb-4">
                <div class="text-center ">
                    <b-skeleton-img
                        v-if="loaded.indexOf(item.id) < 0"
                        style="aspect-ratio: 320 / 480;"
                    />
                    <img v-show="loaded.indexOf(item.id) > -1" :src="item.imgUrl" alt="generica_modulo" class="img-fluid img-module"
                         @load="loaded.push(item.id)">
                </div>
            </div>
        </div>

        <BRow>
            <BCol class="text-end mt-3" sm="12">
                <button class="btn btn-outline-danger" @click="cancel">Cancelar</button>
                <button class="btn btn-outline-success ml-1" @click="save">Salvar Alterações</button>
            </BCol>
        </BRow>
    </div>
</template>

<script>
import Sortable from 'sortablejs';

export default {
    data() {
        return {
            items: [],
            loaded: [],
            color: null
        };
    },
    mounted() {
        this.items = JSON.parse(JSON.stringify(this.$store.state.mentoring.modules));
        this.$nextTick(() => {
            // Configura o SortableJS para o container principal (não pode arrastar para subgrupos)
            Sortable.create(this.$refs.mainContainer, {
                group: {
                    name: 'main-group',
                    pull: 'clone', // permite apenas clonar, mas não mover para subgrupos
                    put: false,    // impede que os itens principais sejam colocados em outro grupo
                },
                animation: 150,
                onEnd: (event) => {
                    const movedItem = this.items.splice(event.oldIndex, 1)[0];
                    this.items.splice(event.newIndex, 0, movedItem);
                },
            });
        });
    },
    methods: {
        cancel() {
            this.$emit('set-type', 'preview');
            this.items = JSON.parse(JSON.stringify(this.$store.state.mentoring.modules));
        },
        save() {
            this.$store.commit('mentoring/setModules', this.items);
            this.$emit('set-type', 'preview');
        }
    },

    created() {
        this.color = document.getElementById('mentoring_color');
    }
};
</script>

<style scoped>

.col-md-3.mb-4 {
    cursor: grab;
}
</style>
